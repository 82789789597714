$gutter: 8px;
$default: #5c5c5c; 
// $default: #333; 
$default-light:#F9F7F8;
$primary: #EA5D6E;
$primary-hover: #D14455;
$primary-light:#FEF1F3;
$primary-ligthen:#b6e1cd;
$primary-darkLight:#F37B89;
$success: #74a732;
$white:#fff;
$white_mute:#f8f8f8;
$black:#000;
$danger: #ff5252;
$warning:#f39104;
$text_muted:#ccc;
$default-color:#5c5c5c;
$transparent:transparent;
$disabled:#f6f6f6;
$light_dark:#707070;
$light-border:#c7c7c7;
$light-blue-border:#d4daef;
$bg_primary:$primary;
//$bg_secondary:
$bg_success:$success;
$bg_danger:$danger;
$bg_warning:$warning;
//$bg_info:

$bg_card:#f4f4f4;
$bg_sp_card:#fff4f3;
$bg_light:#f9f7f8;

$bg_grey:#999999;
$bg_dark:#5c5c5c;
$bg-gray:#b4b4b4;
$bg_alice_blue:#f0f4f7;
$bg_lightgrey:#eff0f0;
$bg_light_smoke:#f8f9fa;
$bg_suva_grey: #8b8b8b;


$border-color:#d3d3d3;
$border-hover-color:#999;




$table_thead_bg: #8b8b8b;

$search-bg-dark:#eaeaea;



// ### patient portal color code #############
$border-color:#dbe1ea;
$border-color1:#d3d3d3;
$border-color2:#b5c5d6;
$border-color3:#e5dfdf;
$border-color4:#b8e7f7;
$border-color5:#cceacc;
$border-color6:#fadcdc;
$border-color7:#dfd8fd;
$border-color8:#e6e6b3;
$border-color9:#d6d8eb;


$bg-color:#e7efef;
$bg-color1:#f4fafc;
$bg-color2:#f8fff8;
$bg-color3:#fff7f7;
$bg-color4:#f7f5ff;
$bg-color5:#fdfdf8;
$bg-color6:#f2f3ff;
$bg-color7:#d6d3d3;
$bg-color8:#a0cb7b;