// .table{
// 	thead{background-color: $table_thead_bg;
// 		th, td{padding: 0.4rem 0.5rem;  color: $white; border: none; border-right: solid 1px rgba(0, 0, 0, 0.1); font-weight: 400;}
// 		tr:first-child{
// 			th:first-child, td:first-child{border-top-left-radius: 0.4rem;}
// 			th:last-child, td:last-child{border-top-right-radius: 0.4rem; border-right: none;}
// 		}
// 	}
// 	tbody{
// 		td{padding: 0.4rem 0.5rem;  border: solid 1px rgba(0, 0, 0, 0.1);
// 		}
// 	}
// }
//MHC Compare  compare
.mhc-compare {
    .table-compare {
        //border: solid 1px $light-border;
        .table-width {
            min-width:620px;
        }
        thead tr th {
            background-color: #faf8f8;
            color: $default;
            border: solid 1px $light-border;
            
        }
        thead tr th:nth-child(1){
            min-width: 200px;
        }
        thead tr th:nth-child(2),    thead tr th:last-child  {
        min-width: 400px;
        }
        tbody tr td {
            border: solid 1px $light-border;
            color: $default;
            background-color: $white;
       
        }
        .table>:not(:first-child) {
            border-top: solid 1px $light-border;
        }
        .table>:not(caption)>*>* {
            padding: 1rem 1rem;
        }
        .vendor-image {
            width: 100px;
            height: auto;
        }
        @media only screen and (max-width: 575px) {
            .vendor-image {
                width: 70px;
                height: auto;
            }
        }
    }
}
.bg-cell{
    background-color: #faf8f8 !important;
    vertical-align: middle;
    border-bottom: solid 1px #faf8f8 !important;
}
.bg-cell-last{
    background-color: #faf8f8 !important;
    vertical-align: middle;
}
// ###########################################package table ########################################
.mhc-package-table{
 td{
     border:solid 1px $light-blue-border;
     border-collapse: collapse;
 }
}

.table>:not(:first-child) {
    border-top: 1px solid currentColor;
}

.mhc-document-table{
    min-width: 700px;
    
    td,th{
        border-bottom:solid 0.5px $border-color1;
        color:$default;
        padding: 0.8rem 0.5rem;
    }
    th{
        font-size: 1.1rem;
        @media only screen and (max-width: 575.98px) {
            font-size: 1rem;
        }
    }
    td{
        font-size: 1rem;
        .test-icon{
            height: 20px;
            width: auto;
            margin-right: 5px;
        
        }
        .downloadbtn{
            height: 15px;
            width: auto;
            cursor: pointer;
        }
    }
}