input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px $white inset !important;
	-moz-box-shadow: 0 0 0 30px $white inset !important;
	-ms-box-shadow: 0 0 0 30px $white inset !important;
	-o-box-shadow: 0 0 0 30px $white inset !important;
	box-shadow: 0 0 0 30px $white inset !important;
}

input,
textarea,
select {
	filter: none;
}


.form-control,
.form-select {
	height: calc(1.6em + .75rem + 2px);
	border-color: $border-color;
	padding-left: 0.55rem;
	padding-right: 0.55rem;
	color: $default;

	&:focus {
		border-color: $border-color;
		box-shadow: none !important
	}

	&:disabled {
		background: $white !important;
		color: rgba($default, 0.5);
		border-color: rgba($border-color, 0.6);
	}

	&:disabled~label {
		color: rgba($primary, 0.7) !important
	}
}

.input-group-text {
	height: calc(1.6em + .75rem + 2px);
	padding: 0.375rem 0.75rem !important;
}

textarea.form-control {
	height: auto;
	min-height: 44px;
	padding-top: 0.575rem;
	overflow: hidden;
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none;
	// background: url(../images/ic-arrow-down.svg) right/30px 14px transparent no-repeat!important;
	text-overflow: '';
	padding-right: 28px !important;

	&::-ms-expand {
		display: none
	}

	&:focus {
		border-color: $border-hover-color;
		box-shadow: none !important
	}
}


.form-group {
	margin-bottom: 1.25rem;
	position: relative;

	.alert-message {
		position: absolute;
		bottom: -16px;
		left: 14px;
		font-size: 0.75rem;
	}
}

.form-group.customdrop {
	z-index: 999;
}

.form-group,
.form-label-group {
	position: relative;

	::-webkit-input-placeholder {
		color: transparent;
	}

	:-ms-input-placeholder {
		color: transparent;
	}

	::-ms-input-placeholder {
		color: transparent;
	}

	::-moz-placeholder {
		color: transparent;
	}

	::placeholder {
		color: transparent;
	}
}

.form-label-group>label,
.form-group>label {
	top: 6px;
	left: 13px;
	position: absolute;
	display: block;
	outline: none;
	z-index: 99;
	@include transition(.1s);
	margin: 0px;

	&.required span {
		color: $danger;
		display: inline-block;
		padding-left: 4px;
	}
}


.form-group.filldata>label,
.form-label-group.filldata>label {
	font-size: 13px;
	color: $primary;
	top: -10px;
	display: inline-block;
	padding: 0 2px;
	left: 8px;
	background: -moz-gradient(to top, $white 50%, rgba($white, 0) 0%);
	background: -webkit-gradient(to top, $white 50%, rgba($white, 0) 0%);
	background: linear-gradient(to top, $white 50%, rgba($white, 0) 0%);
}

.form-group.filldata {
	.form-control {
		&:disabled~label {
			background: -moz-gradient(to top, rgba($disabled, 1) 50%, rgba($white, 0) 0%);
			background: -webkit-gradient(to top, rgba($disabled, 1) 50%, rgba($white, 0) 0%);
			background: linear-gradient(to top, rgba($disabled, 1) 50%, rgba($white, 0) 0%);
		}
	}

}

.form-group.input-group.filldata>label {
	top: -11px;
}

.form-group.input-group {
	border: solid 1px $border-color;
	border-radius: .25rem;
	@include transition(0.15s);

	.form-control {
		border: none !important;
		height: calc(1.4em + .75rem);
	}

	.input-group-addon {
		background: $white;
		padding: 0px 10px;
		position: relative;

		.btn {
			padding-top: 0.25rem;
			padding-bottom: 0.25rem;
		}

		i,
		select {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			position: relative;
			opacity: 0.8;
		}

		select {
			border-color: transparent;
			padding-right: 16px;
			outline: none;
			background-size: 12px 13px;

			&:focus {
				outline: none
			}
		}


	}

	&> :first-child {
		border-radius: .25rem 0 0 .25rem !important;
	}

	.form-control~.input-group-addon {
		border-radius: 0 .25rem .25rem 0;
	}

	&:focus-within {
		border-color: $border-hover-color;
	}

}


.custom-checkbox {
	.custom-control-input {
		width: 1.25rem;
		height: 1.25rem;
		top: 0.25rem;
	}

	.custom-control-label {
		&::before {
			border-radius: .15rem;
			width: 1.2rem;
			height: 1.2rem;
			top: 0.05rem;
			border-color: $border-color !important
		}
	}

	.custom-control-input:checked~.custom-control-label {
		&::before {
			border-color: $primary !important;
			background-color: $white
		}

		&::after {
			background-image: none;
			left: -1.07rem;
			width: .4rem;
			height: 0.8rem;
			top: .15rem;
			border-bottom: .125rem solid $primary;
			border-right: .125rem solid $primary;
			transform: rotate(45deg);
			display: block;
		}
	}

	.custom-control-input:focus~.custom-control-label::before {
		box-shadow: none;
		outline: none;
	}

	.custom-control-input:not(:disabled):active~.custom-control-label {
		&::before {
			background-color: transparent;
			border-color: $border-color;
		}
	}

	.custom-control-input:disabled:checked~.custom-control-label {
		&::before {
			background-color: rgba($white, 0.5);
			border-color: rgba($primary, 0.5) !important;
		}

		&::after {
			border-color: rgba($primary, 0.5);
		}
	}
}

.custom-radio {
	.custom-control-input {
		width: 1.25rem;
		height: 1.25rem;
		top: 0.25rem;
	}

	.custom-control-label {
		&::before {
			border-radius: 50%;
			width: 1.1rem;
			height: 1.1rem;
			top: 0.1rem;
			border-color: $border-color !important
		}
	}

	.custom-control-input:checked~.custom-control-label {
		&::before {
			border-color: $primary !important;
			background-color: transparent
		}

		&::after {
			background-image: none;
			background-color: $primary;
			left: -1.25rem;
			top: 0.325rem;
			width: 0.6rem;
			height: 0.6rem;
			border-radius: 50%;
			display: block;
		}
	}

	.custom-control-input:focus~.custom-control-label::before {
		box-shadow: none;
		outline: none;
	}

	.custom-control-input:not(:disabled):active~.custom-control-label {
		&::before {
			background-color: transparent;
			border-color: $border-color;
		}
	}
}

.custom-control.mt-2 {
	margin-top: 0.4rem !important;
}



/* ############################ Button ############################################## */
.btn {
	padding: 0.4rem 1rem;
	border-width: .075rem;
	border-radius: 10px;

	@media only screen and (max-width: 575.98px) {
		font-size: 1rem !important;
	}

	&.btn-primary {
		border-color: $primary;
		color: $white;
		background-color: $primary;

		&:hover,
		&:active,
		&:focus {
			border-color: $primary-hover;
			background-color: $primary-hover !important;
		}
	}

	&.btn-outline-primary {
		border-color: $primary;
		color: $primary;

		&:hover,
		&:active,
		&:focus {
			border-color: $primary !important;
			background: $primary !important;
			color: $white !important;
		}
	}

	&.btn-secondary {
		border-color: $default;
		background-color: $default;
		color: $white;

		&:hover,
		&:active,
		&:focus {
			border-color: $default;
			background-color: $default;
			color: $white;
		}
	}

	&.btn-outline-secondary {
		border-color: $default;
		color: $default;

		&:hover,
		&:active,
		&:focus {
			border-color: $primary !important;
			color: $primary !important;
			background-color: transparent !important;
		}
	}
}

.btn:focus,
.btn:active {
	box-shadow: none !important;
	outline: none !important;
}

.btn-text {
	padding: 0.3rem 0.75rem !important;
	height: calc(1.4em + 0.75rem + 2px) !important;
}


.btn-custom {
	border-color: $primary;
	color: $white;
	background-color: $primary;

	&:hover {
		border-color: $primary;
		color: $white;
		background-color: $primary;
	}
}

.btn-outline-custom {
	border-color: $primary;
	color: $default;
	background-color: $white;

	&:hover {
		border-color: $primary;
		color: $white;
		background-color: $primary;
	}
}

.bg-custom {
	background-color: $primary;
	border-color: $primary;
}

.custom-text {
	color: $primary !important;
}